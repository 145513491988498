const key = generateUUID();
var uuidInput;

shadowRoot =
    (document.querySelector(`[flow-id="trip-planning-request"]`) &&
        document.querySelector(`[flow-id="trip-planning-request"]`)
        .shadowRoot) || document;

document.addEventListener('DOMContentLoaded', () => {
    console.log('DOMContentLoaded');
    uuidInput=shadowRoot.getElementById('uuid');
    if(uuidInput){
        uuidInput.value = key;
    }else {
         console.log('on DOMContentLoaded:cannot select element with id');
    }
});

window.addEventListener('heyflow-screen-view', (event) => {
    console.log('heyflow screen view:', event.detail);
    uuidInput=shadowRoot.getElementById('uuid');
    if(uuidInput){
        uuidInput.value = key;
    }else {
         console.log('on DOMContentLoaded:cannot select element with id');
    }
});

window.addEventListener('heyflow-submit', (event) => {
    console.log('heyflow submit:', event.detail);
    console.log('key is:'+key)
    callAnswer(key,5000);
});

var requestOptions = {
   method: 'GET',
   redirect: 'follow'
 };
 
var answerExist=false;
var answerFetchContinue=true;
function callAnswer(uuid,after){
   setTimeout(function(){
      var tryCall=0;
      setInterval(function(){
         if(tryCall>=10){
            return;
         }
         if(answerFetchContinue){
               fetch("https://api.thinkautomation.com/addmessage?taid=64aab211e5b71256fcff4e7364b53bd8e5b7120efc2181fd8RzDfJVFNPDvQk2P%2bniCj9jH%2fhYf%2fkyD&results=true&x-uuid="+uuid, requestOptions)
                  .then(response => response.text())
                  .then(result => {
                     console.log(result);
                     answerApplyer(result)
                  })
            .catch(error => console.log('error', error));
         }else{
             return;
         }
         tryCall++;
      },5000)
      
   },after);
}

function answerApplyer(mes_response){
   if (mes_response && mes_response.trim() !== '') {
      const response = JSON.parse(mes_response);
      if(response.answered==1){
         answerExist=true;
         answerFetchContinue=false;
       //  shadowRoot.getElementById('answer').innerHTML = response.answer;
         shadowRoot.getElementById('answer').innerHTML = formatItineraryToHTML(response.answer);
      }else{
         answerFetchContinue=false;
      }
   }
}

function formatItineraryToHTML(text) {
   // Split the text into days
   const days = text.split('\n\nDay ');
 
   // Initialize the HTML string
   let html = '<div>';
 
   // Loop through each day starting from index 1 (index 0 is an empty string)
   for (let i = 1; i < days.length; i++) {
     const dayText = days[i].split('\n- ');
     const dayNumber = dayText[0].trim();
     const activities = dayText.slice(1);
 
     // Format the activities for the day
     let formattedActivities = '';
     activities.forEach((activity, index) => {
       const [timeRange, description] = activity.split(': ');
       const formattedTimeRange = timeRange.replace(/-/g, ' to ');
       formattedActivities += `<li>${formattedTimeRange}: ${description}</li>`;
     });
 
     // Create the HTML for the day
     html += `
       <div>
         <h3>Day ${dayNumber}</h3>
         <ul>${formattedActivities}</ul>
       </div>
     `;
   }
 
   // Close the main div
   html += '</div>';
 
   return html;
 }


function generateUUID() {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
     const r = Math.random() * 16 | 0,
           v = c == 'x' ? r : (r & 0x3 | 0x8);
     return v.toString(16);
   });
 }
